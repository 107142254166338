<template>
  <div class="content">
    <ul class="menus">
      <li>{{ week }}中队周任务完成情况分析</li>
    </ul>
    <div class="nkuang">
      <team :team-id="teamId" @teamChange="teamChange" />
      <table
        width="98%"
        border="0"
        align="center"
        cellpadding="0"
        cellspacing="0"
        class="tb"
      >
        <tr>
          <th class="l">网格/任务项</th>
          <th>考勤率</th>
          <th>评分率</th>
          <th>劝导率</th>
        </tr>
        <tr v-for="(item, index) in table" :key="index">
          <td class="l">{{ item.CellName }}</td>
          <td>{{ item.AttendanceRate }}</td>
          <td>{{ item.GradeRate }}</td>
          <td>{{ item.AdviseRate }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import team from '@/components/Echarts/Team'
  import store from '@/store'
  import { getCompleteRate } from '@/api/echarts/teamTarget/teamTarget'
  import { Toast } from 'vant'

  export default {
    name: 'CompleteRate',
    components: { team },
    props: {
      initTeamId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        teamId: null,
        week: '',
        table: [],
      }
    },
    created() {
      this.teamId = this.initTeamId
      this.fetchData()
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })

        const { success, data, msg } = await getCompleteRate({
          TeamId: this.teamId,
        })
        if (success) {
          this.week = data.Week
          this.table = data.Info
        } else {
          Toast.fail(msg)
        }

        Toast.clear()
      },

      //中队选择器回调
      teamChange(value) {
        this.teamId = value
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  div,
  ul,
  li {
    margin: 0px;
    padding: 0px;
  }
  body {
    margin: 0px;
    padding: 0px;
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 11px 11px;
  }
  .content {
    width: 80%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .menus li:nth-last-child(1) {
    background: #07585d;
  }
  .wkuang {
    border: 2px #003a3e solid;
    border-radius: 15px;
    padding: 5px;
    margin-top: 6px;
  }
  .nkuang {
    border: 1.2px #888888 dashed;
    border-radius: 15px;
    padding: 10px;
    font-size: 20px;
  }
  .nkuang span {
    color: #f20000;
  }
  /*列表样式重写开始*/
  body {
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 12px 12px;
  }
  .content {
    width: 90%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c !important;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .nkuang {
    margin-top: 20px;
    border: 2px #313234 dashed;
    background: #fff;
  }

  .tb {
    font-size: 14px;
  }
  .tb th {
    background: #ececec;
    height: 30px;
  }
  .tb td {
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #666;
  }
  .tb .l {
    text-align: left !important;
    padding-left: 10px !important;
  }
  .xz {
    margin-bottom: 10px;
    margin-top: 5px;
  }
</style>
