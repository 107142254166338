import request from '@/util/request'

export function getCompleteRate(params) {
  return request({
    url: '/TeamTarget.asmx/GetCompleteRate',
    method: 'GET',
    params,
  })
}

export function getAdviseCount(params) {
  return request({
    url: '/TeamTarget.asmx/GetAdviseCount',
    method: 'GET',
    params,
  })
}
