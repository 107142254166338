<template>
  <div>
    <complete-rate :init-team-id="teamId" />
    <advise-count />
    <div class="bottom">
      <img
        :src="require('@/assets/images/echarts/outDoor/bottom.png')"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import completeRate from '@/views/echarts/teamTarget/components/completeRate'
  import adviseCount from '@/views/echarts/teamTarget/components/adviseCount'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Index',
    components: { completeRate, adviseCount },
    data() {
      return {
        teamId: null,
      }
    },
    created() {
      const teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.teamId = teamInfo.Team_ID
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'teamTargetEcharts',
        '城管报表-任务情况分析',
        'zzdcg.yy.gov.cn/index'
      )
    },
  }
</script>

<style scoped></style>
