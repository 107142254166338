<template>
  <div class="content">
    <ul class="menus">
      <li>{{ year }}年中队劝导数量分析</li>
    </ul>
    <div class="nkuang" style="height: 220px">
      <div id="adviseCountCharts" style="height:100%"></div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  import { getAdviseCount } from '@/api/echarts/teamTarget/teamTarget'
  import { Toast } from 'vant'

  export default {
    name: 'CompleteRate',
    data() {
      return {
        year: '',
        yangMing: [],
        fengShan: [],
        liZhou: [],
        lanJiang: [],
        total: [],
      }
    },
    created() {
      this.year = new Date().getFullYear()
      this.fetchData()
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })

        const { success, data, msg } = await getAdviseCount({})
        if (success) {
          this.yangMing = data.YangMing
          this.fengShan = data.FengShan
          this.liZhou = data.LiZhou
          this.lanJiang = data.LanJiang
          this.total = data.Total
          this.setEcharts()
        } else {
          Toast.fail(msg)
        }

        Toast.clear()
      },

      //图表生成
      setEcharts() {
        let myChart = echarts.init(document.getElementById('adviseCountCharts'))
        let option
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          legend: {
            data: ['阳明中队', '凤山中队', '梨洲中队', '兰江中队', '全局'],
          },
          grid: {
            left: '1%',
            right: '4%',
            bottom: '1%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: [
                '1月',
                '2月',
                '3月',
                '4月',
                '5月',
                '6月',
                '7月',
                '8月',
                '9月',
                '10月',
                '11月',
                '12月',
              ],
              axisLabel: { interval: 0, rotate: 0 },
            },
          ],
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: [
            {
              name: '阳明中队',
              type: 'line',
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series',
              },
              data: this.yangMing,
            },
            {
              name: '凤山中队',
              type: 'line',
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series',
              },
              data: this.fengShan,
            },
            {
              name: '梨洲中队',
              type: 'line',
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series',
              },
              data: this.liZhou,
            },
            {
              name: '兰江中队',
              type: 'line',
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series',
              },
              data: this.lanJiang,
            },
            {
              name: '全局',
              type: 'line',
              stack: 'Total',
              areaStyle: {},
              emphasis: {
                focus: 'series',
              },
              data: this.total,
            },
          ],
        }

        if (option && typeof option === 'object') {
          myChart.setOption(option)
        }
      },
    },
  }
</script>

<style scoped>
  div,
  ul,
  li {
    margin: 0px;
    padding: 0px;
  }
  body {
    margin: 0px;
    padding: 0px;
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 11px 11px;
  }
  .content {
    width: 80%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .menus li:nth-last-child(1) {
    background: #07585d;
  }
  .wkuang {
    border: 2px #003a3e solid;
    border-radius: 15px;
    padding: 5px;
    margin-top: 6px;
  }
  .nkuang {
    border: 1.2px #888888 dashed;
    border-radius: 15px;
    padding: 10px;
    font-size: 20px;
  }
  .nkuang span {
    color: #f20000;
  }
  /*列表样式重写开始*/
  body {
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 12px 12px;
  }
  .content {
    width: 90%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c !important;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .nkuang {
    margin-top: 20px;
    border: 2px #313234 dashed;
    background: #fff;
  }

  .tb {
    font-size: 14px;
  }
  .tb th {
    background: #ececec;
    height: 30px;
  }
  .tb td {
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #666;
  }
  .tb .l {
    text-align: left !important;
    padding-left: 10px !important;
  }
  .xz {
    margin-bottom: 10px;
    margin-top: 5px;
  }
</style>
